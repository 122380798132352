<template>
    <div>
        <div class="d-none d-sm-none d-md-none d-lg-none d-xl-block">
             <div class="col-sm-3 col-md-3 col-lg-3 col-xl-12 m-0 p-0 mb-4 mx-auto p-0">
                <img src="../../../../src/assets/FF-Certified-Professional-Logo-300x134.png" alt="Club Princess" class="img-fluid">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-12 m-0 p-0 mb-4 mx-auto p-0">
                <img src="../../../../src/assets/PSY-2021.png" alt="Pet Sitter of the Year Top 5 Finalist" class="img-fluid">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-12 m-0 p-0 mb-4 mx-auto p-0">
                <img src="../../../../src/assets/PSI-Member.png" alt="Pet Sitters International Member" class="img-fluid">
            </div>
            <div class="col-sm-3 col-md-3 col-lg-3 col-xl-12 m-0 p-0 mb-4 mx-auto p-0">
                <img src="../../../../src/assets/Acutraq.png" alt="Acutraq" class="img-fluid">
            </div>
        </div>
        <div class="d-none d-block d-block d-block d-xl-none">
            <div class="row px-4">
                <div class="col-3 mx-auto p-0 w-75">
                    <img src="../../../../src/assets/FF-Certified-Professional-Logo-300x134.png" alt="Club Princess" class="img-fluid">
                </div>
                <div class="col-3 mx-auto p-0 w-75">
                    <img src="../../../../src/assets/PSY-2021.png" alt="Pet Sitter of the Year Top 5 Finalist" class="img-fluid">
                </div>
                <div class="col-3 mx-auto p-0 w-75">
                    <img src="../../../../src/assets/PSI-Member.png" alt="Pet Sitters International Member" class="img-fluid">
                </div>
                <div class="col-3 mx-auto p-0 w-75">
                    <img src="../../../../src/assets/Acutraq.png" alt="Acutraq" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>